<template>
  <div>
    <b-modal
      v-model="IsPopupActiveLocal"
      :title="$t('Create')"
      hide-footer
    >
      <b-container>
        <b-row>
          <b-col
            cols="12"
            class="mt-2"
          >
            <vx-input
              v-model="localCat.name"
              :label="$t('name')"
              :errors="errors.name"
            />
          </b-col>
          <b-col
            class="mt-5 w-full d-flex justify-content-end"
          >
            <b-button
              align-self="end"
              variant="gradient-primary"
              @click="Edit"
            >
              {{ $t('Edit') }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'EditCategory',
  props: ['value'],
  data() {
    return {
      errors: [],
      localCat: {},
    }
  },
  computed: {
    IsPopupActiveLocal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    Cat() {
      return this.$store.getters['category/cat']
    },
  },
  watch: {
    Cat(val) {
      this.localCat = { ...val }
    },
  },
  methods: {
    Edit() {
      this.$store.dispatch('category/Update', { id: this.localCat.id, Data: this.localCat }).then(() => {
        this.IsPopupActiveLocal = false
        this.localCat = {}
      }).catch(error => {
        if (error.response.data.errors) this.errors = error.response.data.errors
      })
    },
  },
}
</script>
