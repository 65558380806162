<template>
  <div>
    <create-category v-model="CreateCategory" />
    <edit-category v-model="EditCategory" />
    <vs-row>
      <vs-col
        class="mb-2"
        vs-type="flex"
        vs-justify="space-between"
      >
        <b-button
          v-b-modal.cat_modal
          variant="gradient-primary"
          @click="addNew"
        >{{ $t('Create') }}
        </b-button>
        <b-button
          variant="gradient-danger"
          @click="$router.push({'name':'categories_trashed'})"
        >{{ $t('Trashed') }}
        </b-button>
      </vs-col>

      <vs-col vs-w="12">
        <div
          id="data-list-list-view"
          class="data-list-container"
        >

          <vs-pagination-table
            model_type="category"
            :data="Categories"
            :table_name="$t('Categories')"
          >
            <template slot-scope="{data}">
              <template>
                <vs-th sort-key="id">
                  {{ $t('ID') }}
                </vs-th>
                <vs-th sort-key="name">
                  {{ $t('Name') }}
                </vs-th>
                <vs-th>{{ $t('Dates') }}</vs-th>
                <vs-th>{{ $t('Options') }}</vs-th>
              </template>
              <template>
                <vs-tr
                  v-for="(tr, index) in data"
                  :key="index"
                >
                  <vs-td>
                    <vs-chip
                      transparent
                      color="primary"
                    >
                      {{ tr.id }}
                    </vs-chip>
                  </vs-td>
                  <vs-td>
                    {{ tr.name }}
                  </vs-td>
                  <vs-td>
                    <div style="width: max-content">
                      <vs-chip
                        :id="'chip_c' + tr.id"
                        transparent
                        color="success"
                      >
                        <vs-avatar
                          color="rgb(var(--vs-success))"
                          icon="event_available"
                        />

                        {{ tr.created_from }}
                      </vs-chip>
                      <b-tooltip
                        variant="success"
                        :target="'chip_c' + tr.id"
                      >
                        {{ tr.created_at }}
                      </b-tooltip>
                    </div>
                    <div style="width: max-content">
                      <vs-chip
                        :id="'chip_u' + tr.id"
                        transparent
                        color="warning"
                      >
                        <vs-avatar
                          color="rgb(var(--vs-warning))"
                          icon="update"
                        />
                        {{ tr.updated_from }}
                      </vs-chip>
                      <b-tooltip
                        transparent
                        :target="'chip_u' + tr.id"
                        variant="warning"
                      >{{ tr.updated_at }}
                      </b-tooltip>
                    </div>
                  </vs-td>
                  <vs-td>
                    <div class="auto-cols-max">
                      <a
                        v-b-modal.cat_modal
                        class="mx-1"
                        href="javascript:void(0)"
                        @click="edit(tr.id)"
                      >
                        <font-awesome-icon
                          color="rgb(var(--vs-success))"
                          :icon="['fas', 'edit']"
                        />
                      </a>
                      <a
                        class="mx-1"
                        href="javascript:void(0)"
                        @click="removeAlert(tr.id)"
                      >
                        <font-awesome-icon
                          color="rgb(var(--vs-danger))"
                          :icon="['fas', 'trash-alt']"
                        />
                      </a>
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </template>
          </vs-pagination-table>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import CreateCategory from '@/views/dashboard/category/Create.vue'
import EditCategory from '@/views/dashboard/category/Edit.vue'

export default {
  components: {
    EditCategory,
    CreateCategory,
  },
  data() {
    return {
      CreateCategory: false,
      EditCategory: false,
    }
  },
  computed: {
    Categories() {
      return this.$store.getters['category/cats']
    },
  },
  created() {
    this.$store.dispatch('category/GetData')
  },
  methods: {
    deleteElement() {
      this.$store.dispatch('category/Delete', { id: this.categoryId })
    },
    addNew() {
      this.CreateCategory = true
    },
    edit($val) {
      this.$store.dispatch('category/GetCat', $val)
      this.EditCategory = true
    },
    removeAlert(val) {
      this.categoryId = val
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm Delete'),
        text: this.$t('Are you sure you want to delete this element'),
        accept: this.deleteElement,
        acceptText: this.$t('Delete'),
        cancelText: this.$t('Cancel'),
      })
    },
  },
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
