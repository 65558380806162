<template>
  <div>
    <b-modal
      v-model="IsPopupActiveLocal"
      :title="$t('Create')"
      hide-footer
    >
      <b-container>
        <b-row>
          <b-col
            cols="12"
            class="mt-2"
          >
            <vx-input
              v-model="localData.name"
              :label="$t('name')"
              :errors="errors.name"
            />
          </b-col>
          <b-col
            class="mt-5 w-full d-flex justify-content-end"
          >
            <b-button
              align-self="end"
              variant="gradient-primary"
              @click="save"
            >
              {{ $t('Save') }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'CreateCategory',
  props: ['value'],
  data() {
    return {
      errors: [],
      localData: {
        name: '',
      },
    }
  },
  computed: {
    IsPopupActiveLocal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    save() {
      this.$store.dispatch('category/Store', this.localData).then(() => {
        this.IsPopupActiveLocal = false
        this.localData.name = ''
      }).catch(error => {
        if (error.response.data.errors) this.errors = error.response.data.errors
      })
    },
  },
}
</script>
